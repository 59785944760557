import { Loading } from 'colosseum';
import { useAgencyList } from 'hooks/agencyHooks';
import { useDocumentTypes, useEditions } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

export const RefDataContext = createContext(undefined);

export const RefDataProvider = ({ children }) => {
  const { data: agencies, error: agenciesError, isLoading: agenciesLoading } = useAgencyList();
  const { data: docTypes, error: docTypesError, isLoading: docTypesLoading } = useDocumentTypes();
  const { data: editions, error: editionsError, isLoading: editionsLoading } = useEditions();
  const [refData, setRefData] = useState(null);

  if (!agenciesLoading && !docTypesLoading && !editionsLoading && !refData) {
    const currentEdition = editions[0].value;
    editions[0].label = 'Current';

    // handle doctypes and groups...
    const documentTypeGroups = {
      all: {
        docTypes: [],
        id: 'all',
        name: 'All'
      },
      ir: {
        docTypes: [],
        id: 'ir',
        name: 'IR'
      }
    };
    const documentTypesByCode = new Map();
    const documentTypesById = new Map();
    docTypes?.iar_ir_doc_type_list?.forEach((docType) => {
      const thisType = {
        ...docType,
        fullName: `${docType.doc_type} - ${docType.name}`,
        children: docType.subtypes ? docType.subtypes : undefined
      };
      documentTypesByCode.set(docType.doc_type, thisType);
      documentTypesById.set(docType.id, thisType);
      documentTypeGroups.all.docTypes.push(thisType);
      documentTypeGroups.ir.docTypes.push(thisType);
    });
    docTypes?.doctype_groups?.forEach((group) => {
      documentTypeGroups[group.search_index_name] = {
        docTypes: group.doctypes.map((docType) => documentTypesByCode.get(docType)),
        id: group.search_index_name,
        name: group.name
      };
    });

    // handle agencies and topics...
    const agenciesByTitle = new Map();
    const topics = [];
    const topicsById = {};
    agencies.forEach((agency) => {
      agenciesByTitle.set(agency.title_num, agency);
      if (!topicsById[agency.topic_id]) {
        topicsById[agency.topic_id] = {
          topicId: agency.topic_id,
          topicName: agency.topic_name,
          children: []
        };
      }
      topicsById[agency.topic_id].children.push(agency);
    });
    for (const id in topicsById) {
      topics.push(topicsById[id]);
    }

    setRefData({
      agenciesByTitle,
      currentEdition,
      editions,
      documentTypeGroups,
      documentTypesByCode,
      documentTypesById,
      topics
    });
  }

  // Sometimes we only have a title number, but need to display the agency name.
  const getAgencyName = (titleNum) => {
    return refData?.agenciesByTitle?.get(titleNum)?.agency_name;
  };

  // Sometimes we only have a doc type, but need the name
  const getDocTypeName = (docType) => {
    return refData?.documentTypesByCode?.get(docType)?.name;
  };

  // Sometimes we only have a doc type id, but need the name
  const getDocTypeNameById = (docTypeId) => {
    return refData?.documentTypesById?.get(docTypeId)?.name;
  };

  // ...or we need the alpha code.
  const getDocTypeCodeById = (docTypeId) => {
    return refData?.documentTypesById?.get(docTypeId)?.doc_type;
  };

  return (
    <RefDataContext.Provider
      value={{
        currentEdition: refData?.currentEdition,
        documentTypes: refData?.documentTypeGroups,
        editions: refData?.editions,
        errors: {
          agenciesError,
          docTypesError,
          editionsError
        },
        getAgencyName,
        getDocTypeCodeById,
        getDocTypeName,
        getDocTypeNameById,
        isLoading: agenciesLoading || docTypesLoading || editionsLoading,
        topics: refData?.topics
      }}
    >
      {agenciesLoading || docTypesLoading || editionsLoading ? <Loading /> : <>{children}</>}
    </RefDataContext.Provider>
  );
};

RefDataProvider.propTypes = {
  children: PropTypes.any
};
