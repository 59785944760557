import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { API, getAuthData, getToken, postData } from 'util/API';
import { encodedLsaDocNumber } from 'util/documentUtil';

/**
 * Create an INtake submission, and return the data, which includes the newly created doc's
 * ID.
 *
 * @param docSubmission
 * @param token
 * @returns {Promise<any>}
 */
const createIntakeSubmission = async (docSubmission, token) => {
  const primaryDocRes = await fetch(`${API}/auth/createIntakeSubmission`, {
    body: JSON.stringify({
      doc_type: docSubmission.docType,
      lsa_doc_number: docSubmission.lsaNumber ? encodedLsaDocNumber(docSubmission.lsaNumber) : null,
      notes: docSubmission.notes,
      title: docSubmission.title
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  if (!primaryDocRes.ok) {
    throw new Error('Failed to create primary doc');
  }
  const primaryData = await primaryDocRes.json();
  return primaryData;
};

/**
 * Create an INtake primary document upload record. This will return a
 * presigned URL that the document can be uploaded to.
 *
 * @param docUpload
 * @param token
 * @returns {Promise<any>}
 */
const uploadIntakePrimaryDoc = async (docUpload, token) => {
  const primaryDocRes = await fetch(`${API}/auth/uploadPrimaryIntakeDoc`, {
    body: JSON.stringify({
      intake_submission_id: docUpload.intake_submission_id,
      filename: docUpload.primaryDocument.name,
      filetype: docUpload.primaryDocument.mimeType
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  if (!primaryDocRes.ok) {
    throw new Error('Failed to create primary doc');
  }

  // upload content...
  const data = await primaryDocRes.json();
  await uploadToPresignedURL(data.presignedPutUrl, docUpload.primaryDocument);
};

/**
 * Uploads content to a pre-signed URL
 * @param presignedPutUrl
 * @param doc
 * @returns {Promise<void>}
 */
const uploadToPresignedURL = async (presignedPutUrl, doc) => {
  const contentRes = await fetch(presignedPutUrl, {
    body: doc.data,
    headers: {
      'Content-Type': doc.mimeType
    },
    method: 'PUT'
  });

  if (!contentRes.ok) {
    throw new Error(`Failed to upload ${doc.name}`);
  }
};

/**
 * Upload a suppporting document - first calls the `uploadSupportingIntakeDoc` endpoint, and then
 * uploads the document to the pre-signed URL that the endpoint returns.
 *
 * @param doc
 * @param intakeSubmissionId
 * @param token
 * @returns {Promise<void>}
 */
const uploadSupportingDoc = async (doc, intakeSubmissionId, token) => {
  const supDocRes = await fetch(`${API}/auth/uploadSupportingIntakeDoc`, {
    body: JSON.stringify({
      filename: doc.name,
      filetype: doc.mimeType,
      intake_submission_id: intakeSubmissionId
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
  if (!supDocRes.ok) {
    throw new Error(`Failed to create ${doc.name}`);
  }
  const supDocData = await supDocRes.json();
  await uploadToPresignedURL(supDocData.presignedPutUrl, doc);
};

/**
 * Reducer to maintain the state of the hook.
 * @param state The current state
 * @param action The action to perform
 * @returns {(*&{currentStep: *})|(*&{state: string})|{currentStep: number, state: string, steps: [{label: string, status: string},{label, status: string}]}}
 */
const uploadReducer = (state, action) => {
  switch (action.type) {
    // Set the state when the upload starts
    case 'start': {
      const steps = [
        { label: 'Creating Document Submission', status: 'active' },
        { label: action.payload.primaryDocument.name, status: '' }
      ];
      if (action.payload.supportingDocuments?.length) {
        action.payload.supportingDocuments.forEach((doc) => {
          steps.push({ label: doc.name, status: '' });
        });
      }
      return {
        currentStep: 0,
        state: '',
        steps
      };
    }

    // advance to the next step
    case 'advance': {
      const newStep = state.currentStep + 1;
      const newState = {
        ...state,
        currentStep: newStep
      };
      newState.steps[state.currentStep].status = 'complete';
      if (newStep < state.steps.length) {
        newState.steps[newStep].status = 'active';
      } else {
        newState.state = 'complete';
      }
      return newState;
    }

    // set an error state
    case 'error': {
      const newState = {
        ...state,
        state: 'error'
      };
      newState.steps[state.currentStep].status = 'error';
      return newState;
    }
  }
};

/**
 * Hook to encapsulate creating a document submission, adding content and supporting documents,
 * while maintaining a status that a UI component can use to provide progress feedback to the user.
 *
 * @returns {{submitDocument: ((function(*): Promise<void>)|*), status: S}}
 */
export const useSubmitDocument = () => {
  const [status, dispatch] = useReducer(uploadReducer, { state: '', steps: [], currentStep: 0 });
  const history = useHistory();

  const submitDocument = useCallback(
    async (docSubmission) => {
      dispatch({
        payload: docSubmission,
        type: 'start'
      });

      try {
        // need a token...
        const token = await getToken(history);

        // create the intake submission...
        const intakeSubmissionData = await createIntakeSubmission(docSubmission, token);
        dispatch({ type: 'advance' });

        // create the primary document and upload it...
        docSubmission.intake_submission_id = intakeSubmissionData.id;
        await uploadIntakePrimaryDoc(docSubmission, token);
        dispatch({ type: 'advance' });

        // if supporting docs, upload those as well...
        if (docSubmission.supportingDocuments?.length) {
          for (const doc of docSubmission.supportingDocuments) {
            await uploadSupportingDoc(doc, intakeSubmissionData.id, token);
            dispatch({ type: 'advance' });
          }
        }
      } catch (e) {
        dispatch({ type: 'error' });
      }
    },
    [history]
  );

  return {
    status,
    submitDocument
  };
};

const submissionsQueryKey = 'submissions';
export const useDocumentSubmissions = () => {
  const history = useHistory();
  const submissionsUrl = `${API}/auth/getIntakeDocumentSubmissions`;
  const authQueryFn = () => getAuthData(history, submissionsUrl, []);

  return useQuery({
    queryKey: [submissionsQueryKey, submissionsUrl],
    queryFn: authQueryFn,
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response
  });
};

const draftsQueryKey = 'drafts';
export const useDocumentDrafts = () => {
  const history = useHistory();
  const draftsUrl = `${API}/auth/getIntakeDocumentSubmissions?status=draft`;
  const authQueryFn = () => getAuthData(history, draftsUrl, []);

  return useQuery({
    queryKey: [draftsQueryKey, draftsUrl],
    queryFn: authQueryFn,
    enabled: true,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    select: (response) => response
  });
};

export const useConfirmDocumentSubmission = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation({
    mutationFn: async (form) => {
      const confirmUrl = `${API}/auth/createIntakeSubmission/${form.id}`;
      const token = await getToken(history);
      return postData(confirmUrl, token, form, 'PUT');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([submissionsQueryKey]);
      queryClient.invalidateQueries([draftsQueryKey]);
    }
  });
};
