import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { primaryDocumentSubmission } from 'util/shapes';

import styles from './AdminDrafts.module.css';

/**
 *
 * @param docs
 * @returns {JSX.Element}
 * @constructor
 */
export const AdminDrafts = ({ docs }) => {
  const { getDocTypeNameById } = useContext(RefDataContext);

  if (!docs || !docs.length) {
    return <div>No drafts ready for INtake.</div>;
  }

  return (
    <ul className={styles.draftList}>
      {docs.map((doc) => (
        <li key={`draft-${doc.id}`} className={styles.draftContainer}>
          <div className={styles.draft}>
            <div className={styles.din}>{doc.document_din}</div>
            <div className={styles.doctype}>{getDocTypeNameById(doc.doc_type)}</div>
            <ExternalLink
              additionalClassName={styles.link}
              linkText="Open in INtake"
              url={`${process.env.REACT_APP_INTAKE_APP_URL}/${doc.id}`}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

AdminDrafts.propTypes = {
  docs: PropTypes.arrayOf(primaryDocumentSubmission)
};
