import { Loading, Select } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { set } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { useConfirmDocumentSubmission } from 'hooks/INtakeHooks';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { RiFilePdf2Fill, RiFileWordFill } from 'react-icons/ri';
import { parseServerDate, serverDateToDateTime } from 'util/dateUtil';
import { displayLsaDocNumber, encodedLsaDocNumber, generateDIN } from 'util/documentUtil';
import { primaryDocumentSubmission, selectEntry } from 'util/shapes';
import { roundSelectStyle } from 'util/ThemeOverrides';

import styles from './ConfirmationForm.module.css';

const title = {
  confirm: 'Confirm Document Submission',
  move: 'Move to Draft'
};

const cancel = {
  confirm: 'Cancel',
  move: 'Go Back'
};

const submit = {
  confirm: 'Confirm',
  move: 'Confirm and Move'
};

/**
 * Form for confirming
 * @param allDocTypes
 * @param allTitles
 * @param mode
 * @param onClose
 * @param submission
 * @returns {JSX.Element}
 * @constructor
 */
export const ConfirmationForm = ({ allDocTypes, allTitles, mode, onClose, submission }) => {
  const { getDocTypeCodeById } = useContext(RefDataContext);
  const { isLoading, mutateAsync } = useConfirmDocumentSubmission();
  const [data, setData] = useState({
    postedDate: parseServerDate(submission.posted_date),
    lsaNumber: displayLsaDocNumber(submission.lsa_doc_number),
    title: submission.title,
    docType: submission.doc_type,
    din: generateDIN(
      displayLsaDocNumber(submission.lsa_doc_number),
      submission.title,
      getDocTypeCodeById(submission.doc_type),
      parseServerDate(submission.posted_date)
    )
  });

  const convertLink = (s3Url) => {
    return s3Url?.replace(
      `s3://${process.env.REACT_APP_AWS_ENV}-iar-site-doc-storage/`,
      process.env.REACT_APP_INTAKE_BASE
    );
  };

  const setField = (name, value) => {
    setData((cur) => {
      const newVal = {
        ...cur,
        [name]: value
      };
      const docTypeCode = getDocTypeCodeById(Number(newVal.docType));
      newVal.din = generateDIN(newVal.lsaNumber, newVal.title, docTypeCode, newVal.postedDate);
      return newVal;
    });
  };

  const submitConfirmation = async (e) => {
    e.preventDefault();
    const midnightDate = data.postedDate
      ? formatISO(
          set(data.postedDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
          })
        )
      : null;
    try {
      await mutateAsync({
        doc_type: data.docType,
        document_din: data.din,
        id: submission.id,
        lsa_doc_number: encodedLsaDocNumber(data.lsaNumber),
        notes: submission.notes,
        posted_date: midnightDate,
        status: mode === 'move' ? 'draft' : 'submitted',
        title: data.title
      });
      toast.success('Document submission confirmed.', { position: 'top-right' });
      onClose();
    } catch (error) {
      console.error(error);
      toast.error('Error confirming document.', { position: 'top-right' });
    }
  };

  return (
    <form className={styles.confirmationForm} onSubmit={submitConfirmation}>
      <AccentHeader level={2}>{title[mode] ?? 'Confirm'}</AccentHeader>
      <div className={styles.submitter}>
        Submitted: {serverDateToDateTime(submission.submission_timestamp)} by{' '}
        {submission.submission_user_email}
      </div>
      <div className={styles.confirmationFormContent}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.dualInputs}>
              <div>
                <label
                  className={mode === 'move' && !data.lsaNumber ? styles.error : undefined}
                  htmlFor="lsaNumber"
                >
                  LSA Number
                </label>
                <input
                  id="lsaNumber"
                  onChange={(e) => setField('lsaNumber', e.target.value)}
                  pattern="\d{2}-\d{1,4}"
                  placeholder={`format: ${new Date().getFullYear().toString().substring(2)}-123`}
                  type="text"
                  value={data.lsaNumber}
                />
              </div>
              <div>
                <label
                  className={mode === 'move' && !data.postedDate ? styles.error : undefined}
                  htmlFor="postedDate"
                >
                  Posting Date
                </label>
                <DatePicker
                  aria-label="Start Date"
                  selected={data.postedDate}
                  onChange={(date) => setField('postedDate', date)}
                  id="postedDate"
                  calendarClassName="customDatePicker"
                />
              </div>
            </div>
            <div>
              <label
                className={mode === 'move' && !data.title ? styles.error : undefined}
                htmlFor="titleSelection"
              >
                Title
              </label>
              <Select
                customStyles={roundSelectStyle}
                id="titleSelection"
                name="title"
                onChange={(val) => setField('title', val.value)}
                options={allTitles}
                placeholder="Select Title..."
                required={true}
                value={allTitles.find((item) => item.value === data.title)}
              />
            </div>
            <div>
              <label
                className={mode === 'move' && !data.docType ? styles.error : undefined}
                htmlFor="docType"
              >
                Document Type
              </label>
              <Select
                customStyles={roundSelectStyle}
                id="docType"
                name="docType"
                onChange={(val) => setField('docType', val.value)}
                options={allDocTypes}
                placeholder="Select Document Type..."
                required={true}
                value={allDocTypes.find((item) => item.value === `${data.docType}`)}
              />
            </div>
            {data.din ? <div>DIN: {data.din}</div> : null}
            <div>
              <label htmlFor="submissionNotes">Notes</label>
              <textarea id="submissionNotes" value={submission.notes} readOnly={true} rows="5" />
            </div>
            {submission.primary_docs ? (
              <div className={styles.docSection}>
                <AccentHeader level={3} underline="light">
                  Primary Document Versions
                </AccentHeader>
                <ul>
                  {submission.primary_docs.map((child) => (
                    <li key={`primaryDoc-${submission.id}-${child.id}`}>
                      <a
                        download={child.filename}
                        href={convertLink(child.link)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <RiFileWordFill /> {child.filename}
                      </a>
                      {` (v${child.version})`}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {submission.supporting_docs?.length ? (
              <div className={styles.docSection}>
                <AccentHeader level={3} underline="light">
                  Supporting Documents
                </AccentHeader>
                <ul>
                  {submission.supporting_docs.map((child) => (
                    <li key={`supportingDoc-${submission.id}-${child.id}`}>
                      <a
                        download={child.filename}
                        href={convertLink(child.link)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <RiFilePdf2Fill /> {child.filename}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className={styles.dialogButtons}>
        <Button
          action={onClose}
          ariaLabel="close without confirming"
          disabled={isLoading}
          variants="hollow"
        >
          {cancel[mode]}
        </Button>
        <Button
          ariaLabel="confirm data and close dialog"
          disabled={isLoading || (mode === 'move' && !data.din)}
          type="submit"
        >
          {submit[mode]}
        </Button>
      </div>
    </form>
  );
};

ConfirmationForm.propTypes = {
  allDocTypes: PropTypes.arrayOf(selectEntry).isRequired,
  allTitles: PropTypes.arrayOf(selectEntry).isRequired,
  mode: PropTypes.oneOf(['confirm', 'move', '']),
  onClose: PropTypes.func.isRequired,
  submission: primaryDocumentSubmission.isRequired
};
