import { Loading } from 'colosseum';
import { useArticleHtmlOnly } from 'hooks/documentHooks';
import { ArticleHeader } from 'pages/Code/Article/ArticleHeader/ArticleHeader';
import { Error404 } from 'pages/Error/Error404';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { codeTitle } from 'util/shapes';

import styles from './Article.module.css';
import { ArticleNav } from './ArticleNav/ArticleNav';

export const Article = ({ article, scrollTo, title, titleData, year }) => {
  const { hash } = useLocation();
  const { data, error, isLoading } = useArticleHtmlOnly(article, title, year);
  const articleRef = useRef(null);

  // If there's a hash, and it matches an element ID, scroll to it.
  useLayoutEffect(() => {
    if (data && hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        scrollTo(element.offsetTop);
      }
    }
  }, [data, hash, scrollTo]);

  return (
    <div aria-live="polite">
      <Helmet>
        <title>
          Title {title}, {data?.article_name || 'Article loading'} | IARP
        </title>
        <meta
          name="description"
          content={`Text of Title ${title}, Article ${article} of the Indiana Administrative Code`}
        />
      </Helmet>
      {isLoading ? (
        <div className={styles.articleLoadingContainer}>
          <Loading screenreaderText={`Loading Title ${title}, article ${article}`} />
        </div>
      ) : error ? (
        <div className={styles.notFound}>
          <Error404 subPage={true} />
        </div>
      ) : (
        <>
          <div className={styles.headerAndNav}>
            <ArticleHeader
              ref={articleRef}
              articleName={data.article_name}
              articleNumber={article}
              pdfUrl={data.pdf_url}
              titleName={data.title_name}
              titleNumber={title}
            />
            {titleData ? (
              <ArticleNav article={article} title={title} titleData={titleData} year={year} />
            ) : null}
          </div>
          <article
            className={`documentWrapper ${styles.docContent}`}
            dangerouslySetInnerHTML={{ __html: data.doc_html }}
            data-title={title}
            data-article={article}
            ref={articleRef}
          />
        </>
      )}
    </div>
  );
};

Article.propTypes = {
  article: PropTypes.string.isRequired,
  scrollTo: PropTypes.func,
  title: PropTypes.string.isRequired,
  titleData: PropTypes.arrayOf(codeTitle),
  year: PropTypes.string.isRequired
};
