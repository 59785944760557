import { Button } from 'components/Button/Button';
import { ConfirmationForm } from 'pages/MyIAR/Home/INtake/INtakeSubmission/INtakeDocumentSubmission/ConfirmationForm/ConfirmationForm';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { RiEdit2Line } from 'react-icons/ri';
import { serverDateToDateTime } from 'util/dateUtil';
import { displayLsaDocNumber } from 'util/documentUtil';
import { primaryDocumentSubmission, selectEntry } from 'util/shapes';

import styles from './INtakeDocumentSubmission.module.css';

/**
 * Individual INtake submission
 * @param allDocTypes list of doctypes for the dropdown
 * @param allTitles list of titles for the dropdown
 * @param submission the INtake submission
 * @returns {JSX.Element}
 * @constructor
 */
export const INtakeDocumentSubmission = ({ allDocTypes, allTitles, submission }) => {
  const [editorMode, setEditorMode] = useState('');
  const dialogRef = useRef(null);

  const openEditor = (mode) => {
    setEditorMode(mode);
    dialogRef.current?.showModal();
  };

  const closeEditor = () => {
    setEditorMode('');
    dialogRef.current?.close();
  };

  return (
    <>
      <li className={styles.docReceiptContainer}>
        <div className={styles.docReceipt}>
          <span className={styles.lsaNumber}>
            <button
              className={styles.editButton}
              aria-label="Edit Receipt Details"
              aria-haspopup="dialog"
              type="button"
              onClick={() => openEditor('confirm')}
            >
              <RiEdit2Line />
            </button>{' '}
            {displayLsaDocNumber(submission.lsa_doc_number)}
          </span>
          <span className={styles.titleAndType}>
            Title {submission.title}, {submission.doc_type_name}
          </span>
          <span className={styles.submitDate}>
            Submitted: {serverDateToDateTime(submission.submission_timestamp)} by{' '}
            {submission.submission_user_email}
          </span>
          <Button
            action={() => openEditor('move')}
            ariaLabel="Confirm details and move document to draft"
            type="button"
          >
            Move to Draft
          </Button>
        </div>
      </li>
      <dialog className={styles.editDialog} ref={dialogRef}>
        <ConfirmationForm
          allDocTypes={allDocTypes}
          allTitles={allTitles}
          mode={editorMode}
          onClose={closeEditor}
          submission={submission}
        />
      </dialog>
    </>
  );
};

INtakeDocumentSubmission.propTypes = {
  allDocTypes: PropTypes.arrayOf(selectEntry).isRequired,
  allTitles: PropTypes.arrayOf(selectEntry).isRequired,
  submission: primaryDocumentSubmission.isRequired
};
