import { CodeArticleName } from 'components/DocumentAccordion/CodeArticleName/CodeArticleName';
import { CodeTitleName } from 'components/DocumentAccordion/CodeTitleName/CodeTitleName';
import linkStyles from 'components/Link/Link.module.css';
import { SpinningButton } from 'components/SpinningButton/SpinningButton';
import { useArticleXmlOnly } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { downloadPDF, downloadPDFFromHtml } from 'util/documentUtil';

import styles from './ArticleHeader.module.css';

/**
 * Render the metadata info of the article.
 * @param articleName Full article name
 * @param articleNumber Article number
 * @param pdfUrl PDF URL (optional)
 * @param titleName Full title name
 * @param titleNumber title number
 * @returns {JSX.Element}
 * @constructor
 */
export const ArticleHeader = forwardRef(function ArticleHeader(
  { articleName, articleNumber, pdfUrl, titleName, titleNumber },
  ref
) {
  const { year, title: titleParam } = useParams();
  const { refetch } = useArticleXmlOnly(articleNumber, titleNumber, year);
  const [renderingPDF, setRenderingPDF] = useState(false);
  const renderRef = useRef(null);

  const generatePdf = async () => {
    try {
      setRenderingPDF(true);
      const filename = `IAC_Title_${titleNumber}_Article_${articleNumber}`;

      const { data } = await refetch();
      if (data?.doc_xml) {
        await downloadPDF(filename, data.doc_xml);
        setRenderingPDF(false);
      } else {
        // if no xml is available, fallback to generating from HTML
        renderRef.current.innerHTML = ref.current.innerHTML;
        downloadPDFFromHtml(renderRef.current, filename, () => {
          setRenderingPDF(false);
          renderRef.current.innerHTML = null;
        });
      }
    } catch (e) {
      toast.error(e.message);
      setRenderingPDF(false);
    }
  };

  return (
    <div className={styles.articleHeaderWrapper}>
      <Link
        aria-label={`Back to IAC navigation for ${year}`}
        className={`${styles.codeBackLink} ${linkStyles.backLink}`}
        to={`/code/${year}/${titleParam}`}
      >
        <RiArrowLeftSLine size="1rem" /> Back to Indiana Administrative Code
      </Link>
      <div className={styles.articleMeta}>
        <div>
          <CodeTitleName
            additionalClassName={styles.titleName}
            number={titleNumber}
            tagName="h2"
            title={titleName}
          />
          <h3 className={styles.articleName}>
            <CodeArticleName name={articleName} />
          </h3>
        </div>
        <div>
          {pdfUrl ? (
            <a
              aria-label={`download title ${titleNumber}, article ${articleNumber} as a pdf file`}
              className={linkStyles.buttonLink}
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF
            </a>
          ) : (
            <SpinningButton
              ariaLabel="Download as PDF"
              ariaSpinningLabel="Downloading PDF"
              buttonLabel="PDF"
              isSpinning={renderingPDF}
              onClick={generatePdf}
            />
          )}
        </div>
        <div className={styles.hiddenDocument}>
          <article className="documentWrapper print" ref={renderRef} />
        </div>
      </div>
    </div>
  );
});

ArticleHeader.propTypes = {
  articleName: PropTypes.string.isRequired,
  articleNumber: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string,
  titleName: PropTypes.string.isRequired,
  titleNumber: PropTypes.string.isRequired
};
