import { Auth } from 'aws-amplify';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { AuthContext } from 'components/AuthContext/AuthContext';
import { Button } from 'components/Button/Button';
import { FeatureEnabled } from 'components/FeatureFlags/FeatureEnabled/FeatureEnabled';
import linkStyles from 'components/Link/Link.module.css';
import { MainContentWrapper } from 'components/MainContentWrapper/MainContentWrapper';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { Error404 } from 'pages/Error/Error404';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { Link, NavLink, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { claims } from 'util/shapes';
import { timeOfDayString } from 'util/timeUtil';

import { Admin } from './Admin/Admin';
import { AgencyList } from './AgencyList/AgencyList';
import { Dashboard } from './Dashboard/Dashboard';
import styles from './Home.module.css';
import { INtakeAgencyPortal } from './INtake/INtakeAgencyPortal/INtakeAgencyPortal';
import { INtakeSubmission } from './INtake/INtakeSubmission/INtakeSubmission';
import { Notifications } from './Notifications/Notifications';
import { Password } from './Password/Password';
import { Profile } from './Profile/Profile';

export const Home = () => {
  const { isAzureUser, email, firstName, setUser, userClaims } = useContext(AuthContext);
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const isSettingsOpen = path !== location.pathname;
  let toastId;

  const handleSignOut = async () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    try {
      await Auth.signOut();
      setUser(null);
      history.push('/myIAR/login', { message: 'You have successfully signed out' });
    } catch (error) {
      toastId = toast.error(error?.message ?? 'Error signing out', {
        position: 'top-right'
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>MyIAR | IARP</title>
      </Helmet>
      <MainContentWrapper additionalClassName={styles.dashboardWrapper}>
        <div className={isSettingsOpen ? styles.settingsOpen : undefined}>
          <AccentHeader level={1}>MyIAR</AccentHeader>
          <div className={styles.accountInfo}>
            <p className={styles.greeting}>
              Good {timeOfDayString()}
              {firstName ? `, ${firstName}!` : ''}
            </p>
            <div className={styles.accountLine}>
              <span>{email}</span>
              {!isAzureUser ? (
                <Link to="/myIAR/home/profile" className={linkStyles.buttonLink}>
                  Edit
                </Link>
              ) : null}
            </div>
            {!isAzureUser ? (
              <div>
                <Link to="/myIAR/home/password" className={linkStyles.buttonLink}>
                  Change Password
                </Link>
              </div>
            ) : null}
            <div>
              <Button action={handleSignOut}>Sign out</Button>
            </div>
            <div className={styles.settingsList}>
              <NavLink
                className={styles.settingsLink}
                activeClassName={styles.settingsSelectedLink}
                to={`${path}/dashboard`}
              >
                Dashboard
              </NavLink>
              <NavLink
                className={styles.settingsLink}
                activeClassName={styles.settingsSelectedLink}
                to={`${path}/agencyList`}
              >
                Topic/Agency List
              </NavLink>
              <NavLink
                className={styles.settingsLink}
                activeClassName={styles.settingsSelectedLink}
                to={`${path}/notifications`}
              >
                Notification Settings
              </NavLink>
              {userClaims.includes(claims.isAdmin) ? (
                <NavLink
                  className={styles.settingsLink}
                  activeClassName={styles.settingsSelectedLink}
                  to={`${path}/admin`}
                >
                  Admin
                </NavLink>
              ) : null}
              {userClaims.includes(claims.isAdmin) ? (
                <FeatureEnabled feature="intake">
                  <NavLink
                    className={styles.settingsLink}
                    activeClassName={styles.settingsSelectedLink}
                    to={`${path}/intake/agencyportal`}
                  >
                    INtake Agency Portal
                  </NavLink>
                  <NavLink
                    className={styles.settingsLink}
                    activeClassName={styles.settingsSelectedLink}
                    to={`${path}/intake/submissions`}
                  >
                    INtake Document Submission
                  </NavLink>
                </FeatureEnabled>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <Link
            className={
              isSettingsOpen
                ? `${styles.settingsBackLink} ${linkStyles.backLink}`
                : styles.backLinkHidden
            }
            to={path}
          >
            <RiArrowLeftSLine size="1rem" /> Back to MyIAR
          </Link>
          <Switch>
            <PrivateRoute exact path={`${path}/`}></PrivateRoute>
            <PrivateRoute exact path={`${path}/agencyList`}>
              <AgencyList />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/notifications`}>
              <Notifications />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/profile`}>
              <Profile />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/password`}>
              <Password />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/dashboard`}>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/admin`} requiredClaim={claims.isAdmin}>
              <Admin />
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/intake/agencyportal`} requiredClaim={claims.isAdmin}>
              <FeatureEnabled feature="intake" fallback={<Error404 />}>
                <INtakeAgencyPortal />
              </FeatureEnabled>
            </PrivateRoute>
            <PrivateRoute exact path={`${path}/intake/submissions`} requiredClaim={claims.isAdmin}>
              <FeatureEnabled feature="intake" fallback={<Error404 />}>
                <INtakeSubmission />
              </FeatureEnabled>
            </PrivateRoute>
            <Route path="*" component={Error404} />
          </Switch>
        </div>
      </MainContentWrapper>
    </>
  );
};
