import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { useTitleList } from 'hooks/documentHooks';
import { useDocumentSubmissions } from 'hooks/INtakeHooks';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { INtakeDocumentSubmission } from './INtakeDocumentSubmission/INtakeDocumentSubmission';
import styles from './IntakeReceipts.module.css';

export const INtakeSubmission = () => {
  const { currentEdition, documentTypes, getDocTypeNameById } = useContext(RefDataContext);
  const { data: allTitles, isLoading: isTitleDataLoading } = useTitleList(currentEdition);
  const { data, error, isLoading } = useDocumentSubmissions();
  const [augmentedData, setAugmentedData] = useState(null);
  const [allDocTypes, setAllDocTypes] = useState(null);
  const [filterString, setFilterString] = useState('');

  useEffect(() => {
    // we only have the doctype ID, but we need the name for display and filtering.
    if (!isLoading && data?.length > 0) {
      const dataWithTypeNames = data.map((item) => {
        return {
          ...item,
          doc_type_name: getDocTypeNameById(item.doc_type)
        };
      });
      setAugmentedData(dataWithTypeNames);
    } else {
      setAugmentedData([]);
    }
  }, [data, getDocTypeNameById, isLoading]);

  // Need to turn doctypes into an array usable by <Select>
  useEffect(() => {
    setAllDocTypes(() => {
      if (documentTypes?.all?.docTypes?.length) {
        return documentTypes.all.docTypes.map((docType) => {
          return { label: docType.fullName, value: `${docType.id}` };
        });
      }
      return [];
    });
  }, [documentTypes]);

  const filterSubmissions = (submission) => {
    return (
      submission.doc_type_name?.toLowerCase().includes(filterString) ||
      submission.lsa_doc_number?.toLowerCase().includes(filterString) ||
      submission.title?.toLowerCase().includes(filterString) ||
      submission.submission_user_email?.toLowerCase().includes(filterString)
    );
  };

  return (
    <>
      <Helmet>
        <title>INtake Document Submission | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        INtake Document Submission
      </AccentHeader>
      {isLoading || isTitleDataLoading ? (
        <div>
          <Loading />
        </div>
      ) : error ? (
        <div>
          <ErrorMessage>Unable to load Document Submissions</ErrorMessage>
        </div>
      ) : (
        <div>
          {augmentedData?.length ? (
            <>
              <input
                className={styles.filterInput}
                onChange={(e) => setFilterString(e.target.value?.toLowerCase() ?? '')}
                placeholder="Filter..."
                type="search"
              />
              <p>{augmentedData.length} Submissions Ready to Process</p>
              <ul className={styles.receiptList}>
                {augmentedData?.filter(filterSubmissions).map((submission) => (
                  <INtakeDocumentSubmission
                    allDocTypes={allDocTypes}
                    allTitles={allTitles}
                    submission={submission}
                    key={`docSubmission-${submission.id}`}
                  />
                ))}
              </ul>
            </>
          ) : (
            <p>No Submissions Ready</p>
          )}
        </div>
      )}
    </>
  );
};
