import { Loading } from 'colosseum';
import { AccentHeader } from 'components/AccentHeader/AccentHeader';
import { FeatureEnabled } from 'components/FeatureFlags/FeatureEnabled/FeatureEnabled';
import { IARAccordion } from 'components/IARAccordion/IARAccordion';
import Tabs from 'components/Tabs/Tabs';
import { useIrDocs, useNonPublicArticles } from 'hooks/documentHooks';
import { useDocumentDrafts } from 'hooks/INtakeHooks';
import { AdminDrafts } from 'pages/MyIAR/Home/Admin/AdminDrafts/AdminDrafts';
import { DoubleSecretAdminControls } from 'pages/MyIAR/Home/Admin/DoubleSecretAdminControls/DoubleSecretAdminControls';
import { Helmet } from 'react-helmet-async';
import { earliestDataFn, oneYearFutureFn } from 'util/dateUtil';
import { documentStagePrivate, documentStagePrivateReady } from 'util/shapes';

import styles from './Admin.module.css';
import AdminCodeArticles from './AdminCodeArticles/AdminCodeArticles';
import AdminRegisterDocs from './AdminRegisterDocs/AdminRegisterDocs';
import { ToggleAdminModeButton } from './ToggleAdminModeButton/ToggleAdminModeButton';

const oneYearFuture = oneYearFutureFn();
const earliestDate = earliestDataFn();

export const Admin = () => {
  const {
    data: draftDocs,
    error: draftDocsError,
    isLoading: draftDocsIsLoading
  } = useDocumentDrafts();
  const {
    data: privateDocs,
    error: privateDocsError,
    isLoading: privateDocsIsLoading
  } = useIrDocs(earliestDate, oneYearFuture, documentStagePrivate);
  const {
    data: privateReadyDocs,
    error: privateReadyDocsError,
    isLoading: privateReadyDocsIsLoading
  } = useIrDocs(earliestDate, oneYearFuture, documentStagePrivateReady);
  const {
    data: privateArticles,
    error: privateArticlesError,
    isLoading: privateArticlesIsLoading
  } = useNonPublicArticles(documentStagePrivate);

  // Sort function that sorts IR docs by the DIN.
  // e.g. 20240703-IR-025230768RNA
  //      ------------------------
  const byDin = (doc1, doc2) => {
    const din1 = doc1?.din ?? '';
    const din2 = doc2?.din ?? '';

    return din1.localeCompare(din2);
  };

  const allPrivateDocs =
    privateDocs?.find((category) => category.cat_name === 'All Documents')?.docs?.sort(byDin) ?? [];

  const allPrivateReadyDocs =
    privateReadyDocs
      ?.find((category) => category.cat_name === 'All Documents')
      ?.docs?.sort(byDin) ?? [];

  const docsLabel = (docs) => `${docs?.length} IR Document${docs?.length === 1 ? '' : 's'}`;
  const articlesLabel = (articles) =>
    `${articles?.length} Admin Code Article${articles?.length === 1 ? '' : 's'}`;

  return (
    <>
      <Helmet>
        <title>Admin | IARP</title>
      </Helmet>
      <AccentHeader level={2} underline="subtle">
        Admin
      </AccentHeader>
      <div>
        {draftDocsIsLoading ||
        privateDocsIsLoading ||
        privateReadyDocsIsLoading ||
        privateArticlesIsLoading ? (
          <Loading />
        ) : draftDocsError || privateDocsError || privateReadyDocsError || privateArticlesError ? (
          <p>Unable to load documents.</p>
        ) : (
          <>
            <div className={styles.stageControl}>
              <span>Admin Control</span>
              <ToggleAdminModeButton />
            </div>
            <FeatureEnabled feature="intake">
              <IARAccordion title="Draft Documents">
                <Tabs
                  id="draft_tabs"
                  ariaLabel="Draft Document Groups"
                  tabData={[
                    {
                      header: docsLabel(draftDocs),
                      body: <AdminDrafts docs={draftDocs} id="draft_documents" />
                    }
                  ]}
                />
              </IARAccordion>
            </FeatureEnabled>
            <IARAccordion title="Private Documents">
              <Tabs
                id="private_tabs"
                ariaLabel="Private Document Groups"
                tabData={[
                  {
                    header: docsLabel(allPrivateDocs),
                    body: (
                      <AdminRegisterDocs
                        docs={allPrivateDocs}
                        stage={documentStagePrivate}
                        id="private_register_docs"
                        promote={true}
                      />
                    )
                  },
                  {
                    header: articlesLabel(privateArticles),
                    body: (
                      <AdminCodeArticles
                        articles={privateArticles}
                        stage={documentStagePrivate}
                        id="private_articles"
                      />
                    )
                  }
                ]}
              />
            </IARAccordion>
            <IARAccordion title="Private - Ready to Publish">
              <Tabs
                id="private_ready_tabs"
                ariaLabel="Private Ready Document Groups"
                tabData={[
                  {
                    header: docsLabel(allPrivateReadyDocs),
                    body: (
                      <AdminRegisterDocs
                        docs={allPrivateReadyDocs}
                        stage={documentStagePrivateReady}
                        id="private_ready_register_docs"
                        promote={false}
                      />
                    )
                  }
                ]}
              />
            </IARAccordion>
            <DoubleSecretAdminControls />
          </>
        )}
      </div>
    </>
  );
};
