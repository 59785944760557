import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, postData } from 'util/API';

export const useContactMutation = () => {
  const contactUsKey = 'contactUs';
  const queryClient = useQueryClient();
  const url = `${API}/contactUs`;

  return useMutation({
    mutationFn: (form) => {
      return postData(url, false, form);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([contactUsKey]);
    }
  });
};
