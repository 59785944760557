import { Loading, Select } from 'colosseum';
import { Button } from 'components/Button/Button';
import { RefDataContext } from 'components/RefDataContext/RefDataContext';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useTitleList } from 'hooks/documentHooks';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { RiEdit2Fill } from 'react-icons/ri';
import { roundSelectStyle } from 'util/ThemeOverrides';

import styles from './AgencyPortalForm.module.css';
import { FileUploader } from './FileUploader/FileUploader';

const initialErrors = {
  title: null,
  docType: null,
  primaryDoc: null,
  lsaNumber: null
};

export const AgencyPortalForm = ({ onSubmit }) => {
  const { currentEdition } = useContext(RefDataContext);
  const { data: allTitles, isLoading: isTitleDataLoading } = useTitleList(currentEdition);
  const { documentTypes } = useContext(RefDataContext);
  const [primaryDocuments, setPrimaryDocuments] = useState([]);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [errors, setErrors] = useState(initialErrors);
  const [lsaSelection, setLsaSelection] = useState(null);
  const [lsaNumber, setLsaNumber] = useState('');
  const lsaDialogRef = useRef(null);

  const submitForm = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const title = data.get('title');
    const notes = data.get('notes');
    const docType = data.get('docType');
    const validLsaSelection = lsaSelection === 'no' || (lsaSelection === 'yes' && lsaNumber);
    if (primaryDocuments?.length === 1 && validLsaSelection && e.target.checkValidity()) {
      setErrors(initialErrors);
      onSubmit({
        title,
        notes,
        docType,
        lsaNumber,
        primaryDocument: primaryDocuments[0],
        supportingDocuments
      });
      e.target.reset();
      setPrimaryDocuments([]);
      setSupportingDocuments([]);
    } else {
      setErrors({
        title: title ? '' : 'Required',
        docType: docType ? '' : 'Required',
        primaryDoc: primaryDocuments?.length === 1 ? '' : 'Required',
        lsaNumber: validLsaSelection ? '' : 'Required'
      });
    }
  };

  if (isTitleDataLoading) {
    return <Loading />;
  }

  const checkLsaSelection = () => {
    if (lsaSelection === null) {
      lsaDialogRef.current.showModal();
    }
  };

  const dialogIsValid = () => {
    return (
      lsaSelection === 'no' ||
      (lsaSelection === 'yes' && lsaNumber && /\d{2}-\d{1,4}/.test(lsaNumber))
    );
  };

  const closeDialog = () => {
    lsaDialogRef.current.close();
  };

  return (
    <form
      className={styles.uploadForm}
      encType="multipart/form-data"
      noValidate
      onSubmit={submitForm}
    >
      <div className={styles.inputRow}>
        <label className={errors.title ? styles.requiredItemLabel : null} htmlFor="titleSelection">
          Title
        </label>
        <Select
          customStyles={roundSelectStyle}
          id="titleSelection"
          name="title"
          options={allTitles}
          placeholder="Select Title..."
          required={true}
        />
      </div>
      <div className={styles.multiInputRow}>
        <div className={styles.multiInputs}>
          <div>
            <label className={errors.docType ? styles.requiredItemLabel : null} htmlFor="docType">
              Document Type
            </label>
            <Select
              customStyles={roundSelectStyle}
              id="docType"
              name="docType"
              options={documentTypes.all.docTypes.map((docType) => {
                return { label: docType.fullName, value: `${docType.id}` };
              })}
              placeholder="Select Document Type..."
              required={true}
            />
          </div>
          <div>
            <label
              htmlFor="lsaNumber"
              className={errors.lsaNumber ? styles.requiredItemLabel : null}
            >
              LSA Number
            </label>
            <div className={styles.lsaControl}>
              <input
                className={styles.input}
                type="text"
                readOnly
                onFocus={checkLsaSelection}
                placeholder={lsaSelection === 'no' ? 'Automatic' : ''}
                value={lsaSelection === 'yes' ? lsaNumber : ''}
              />
              {lsaSelection ? (
                <Button
                  action={() => lsaDialogRef.current.showModal()}
                  ariaLabel="edit lsa number"
                  aria-haspopup="dialog"
                >
                  <RiEdit2Fill />
                </Button>
              ) : null}
            </div>
            <dialog ref={lsaDialogRef} className={styles.lsaNumberDialog}>
              <p>Do you have an LSA Number for the document?</p>
              <div className={styles.modalInputRow}>
                <input
                  type="radio"
                  onClick={() => setLsaSelection('yes')}
                  name="hasLSANumber"
                  id="hasLSANumberCheckbox"
                />
                <label htmlFor="hasLSANumberCheckbox">Yes</label>
                <input
                  disabled={lsaSelection !== 'yes'}
                  type="text"
                  name="lsaNumber"
                  id="lsaNumber"
                  className={`${styles.input} ${styles.inputBorder}`}
                  pattern="\d{2}-\d{1,4}"
                  placeholder={`format: ${new Date().getFullYear().toString().substring(2)}-123`}
                  value={lsaNumber}
                  onChange={(e) => setLsaNumber(e.target.value)}
                />
              </div>
              <div className={styles.modalInputRow}>
                <input
                  type="radio"
                  onClick={() => setLsaSelection('no')}
                  name="hasLSANumber"
                  id="generateLSANumberCheckbox"
                />
                <label htmlFor="generateLSANumberCheckbox">No. Generate an LSA Number.</label>
              </div>
              <div className={styles.dialogButtons}>
                <Button disabled={!dialogIsValid()} type="button" action={closeDialog}>
                  Done
                </Button>
              </div>
            </dialog>
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <FileUploader
          accept={['application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
          allowMultiple={false}
          fieldName="rulemakingDocument"
          label={
            <span className={errors.primaryDoc ? styles.requiredItemLabel : null}>
              Upload Agency Document in <strong>Word Format</strong> Only{' '}
              <Tooltip id="word-format-tooltip" ariaLabel="">
                <p>
                  If you are using an older version of Word, you will need to update to have{' '}
                  <strong>.docx</strong> format.
                </p>
              </Tooltip>
            </span>
          }
          selectedDocuments={primaryDocuments}
          setSelectedDocuments={setPrimaryDocuments}
        />
      </div>
      <div className={styles.inputRow}>
        <FileUploader
          accept={['application/pdf']}
          allowMultiple={true}
          fieldName="supportingDocuments"
          label={
            <span>
              Upload Supporting Documents in <strong>PDF</strong> Only
            </span>
          }
          selectedDocuments={supportingDocuments}
          setSelectedDocuments={setSupportingDocuments}
        />
      </div>
      <div className={styles.multiInputRow}>
        <div className={styles.multiInputs}>
          <div>
            <label htmlFor="notes" className={styles.itemLabel}>
              Notes
            </label>
            <textarea id="notes" name="notes" rows={5} className={styles.textarea} />
          </div>
          <Button type="submit">Submit</Button>
        </div>
      </div>
    </form>
  );
};

AgencyPortalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
